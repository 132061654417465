export default [
  {
    header: 'Logs',
  },
  {
    title: 'Log Agent Credit',
    route: 'log-agent-transactions',
    icon: 'UserIcon',
    action: 'read',
    resource: 'LogAgentTransactions',
  },
  {
    title: 'Log Member Credit',
    route: 'log-member-transactions',
    icon: 'UserIcon',
    action: 'read',
    resource: 'LogMemberTransactions',
  },
]
