<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <div class="current__locale">
        <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        />
        <span class="ml-50">{{ currentLocale.name }}</span>
      </div>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="onChange(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'EN',
      },
      {
        locale: 'th',
        img: require('@/assets/images/flags/th.png'),
        name: 'TH',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
  methods: {
    onChange(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
