export default [
  {
    header: 'Reports',
  },
  {
    title: 'W/L Agent (Simple)',
    route: 'reports-agent-winlose-simple',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },
  {
    title: 'W/L Agent (Detail)',
    route: 'reports-agent-winlose-detail',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },

  // player
  {
    title: 'W/L Player (Simple)',
    route: 'reports-player-winlose-simple',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },
  {
    title: 'W/L Player (Detail)',
    route: 'reports-player-winlose-detail',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },

  // match
  {
    title: 'W/L Match (Simple)',
    route: 'reports-match-winlose-simple',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },
  {
    title: 'W/L Match (Detail)',
    route: 'reports-match-winlose-detail',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },

  // outstanding
  {
    header: 'Reports Outstanding',
  },
  {
    title: 'Agents Outstanding',
    route: 'reports-agents-outstanding',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },
  {
    title: 'Players Outstanding',
    route: 'reports-players-outstanding',
    icon: 'EyeIcon',
    resource: 'Reports',
    action: 'read',
  },
]
