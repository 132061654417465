/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Dashboard', route: { name: 'dashboard' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'แดชบอร์ด', route: { name: 'dashboard' }, icon: 'HomeIcon', isBookmarked: false },

      { title: 'Accounts', route: { name: 'accounts' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'บัญชีเอเย่นต์', route: { name: 'accounts' }, icon: 'UserIcon', isBookmarked: false },

      { title: 'Members', route: { name: 'members' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'บัญชีสมาชิก', route: { name: 'members' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Clone Member Setting', route: { name: 'member-clone-setting' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'คัดลอกตั้งค่าสมาชิก', route: { name: 'member-clone-setting' }, icon: 'UserIcon', isBookmarked: false },

      { title: 'W/L Member (Simple)', route: { name: 'reports-member-winlose-simple' }, icon: 'EyeIcon', isBookmarked: false },
      { title: 'บัญชี แพ้ชนะ', route: { name: 'reports-member-winlose-simple' }, icon: 'EyeIcon', isBookmarked: false },

      { title: 'W/L Member (Detail)', route: { name: 'reports-member-winlose-detail' }, icon: 'EyeIcon', isBookmarked: false },
      { title: 'บัญชี แพ้ชนะ (รายละเอียด)', route: { name: 'reports-member-winlose-detail' }, icon: 'EyeIcon', isBookmarked: false },

      { title: 'W/L Match (Simple)', route: { name: 'reports-match-winlose-simple' }, icon: 'EyeIcon', isBookmarked: false },
      { title: 'เกมส์ แพ้ชนะ', route: { name: 'reports-match-winlose-simple' }, icon: 'EyeIcon', isBookmarked: false },

      { title: 'W/L Match (Detail)', route: { name: 'reports-match-winlose-detail' }, icon: 'EyeIcon', isBookmarked: false },
      { title: 'เกมส์ แพ้ชนะ (รายละเอียด)', route: { name: 'reports-match-winlose-detail' }, icon: 'EyeIcon', isBookmarked: false },
    ],
  },
}
/* eslint-enable */
