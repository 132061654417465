export default [
  {
    header: 'Billing',
  },
  {
    title: 'My Billing',
    route: 'my-billing',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Billing',
  },
  {
    title: 'Downline Billing',
    route: 'downline-billing',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Billing',
  },
]
