<template>
  <b-row
    class="content-app-breadcrumb"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col
          cols="12"
          class="d-flex align-items-center"
        >
          <!-- <span class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </span> -->
          <div class="breadcrumb-wrapper">

            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumbs"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ getText(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType } from '@/utils/agent'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data() {
    return {
      // breadcrumbs: [],
      oldRouteName: '',
    }
  },
  computed: {
    // breadcrumbs() {
    //   return this.$route.meta.breadcrumb
    // },
    breadcrumbs() {
      return store.getters['appBreadCrumb/getBreadcrumbs']
    },
    userData() {
      return store.getters['auth/user']
    },
  },
  watch: {
    // '$route.meta.breadcrumb': function () {
    //   this.breadcrumbs = this.$route.meta.breadcrumb
    // },
    $route(current) {
      if (current.name !== this.oldRouteName) {
        store.commit('appBreadCrumb/UPDATE_BREADCRUMBS', this.$route.meta.breadcrumb)
      }

      this.oldRouteName = current.name
      // this.breadcrumbs = this.$route.meta.breadcrumb
    },
  },
  mounted() {
    // this.breadcrumbs = this.$route.meta.breadcrumb
    store.commit('appBreadCrumb/UPDATE_BREADCRUMBS', this.$route.meta.breadcrumb)
  },
  methods: {
    getText(text) {
      if (text === '<username>') {
        return String(GetAgentGroupUsername(this.userData)).trim()
      }
      return this.$t(text)
    },
  },
}
</script>
