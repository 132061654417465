<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"> -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <dark-Toggler class="d-block" />
      <b-navbar-nav class="nav align-items-center">
        <search-bar class="d-none d-md-block" />
        <locale />
        <!-- <notification-dropdown /> -->
      <!-- <user-dropdown /> -->
      </b-navbar-nav>
    </div>

    <!-- <div
      class="d-flex flex-column ml-auto"
    >
      <div class="">
        <p class="font-weight-bolder mb-0 text-right">
          {{ user.username }} ({{ GetAgentGroupType(user) }})
        </p>
      </div>
      <div class="text-right">
        <p
          class="user-status credit font-weight-bolder mb-0"
        >{{ DecimalFormat(user.creditLimit) }} THB</p>
      </div>

    </div> -->

    <div
      v-if="loggedIn"
      class="d-flex flex-column ml-auto"
    >
      <div class="">
        <p
          v-if="user.type === 'SUB'"
          class="font-weight-bolder mb-0 text-right"
        >
          {{ user.username }} ({{ user.type }} : {{ GetAgentGroupType(user) }})
        </p>
        <p
          v-else
          class="font-weight-bolder mb-0 text-right"
        >
          {{ user.username }} ({{ GetAgentGroupType(user) }})
        </p>
      </div>
      <div>
        <div
          v-if="!$wait.is('loading-fetch-member')"
          class="d-flex flex-row member-main"
        >
          <div
            class="align-self-center member-credit-reload"
            @click="memberCreditReload"
          >
            <b-icon

              icon="arrow-repeat"
            />
          </div>
          <div class="text-right">
            <p
              class="user-status credit font-weight-bolder mb-0"
            >{{ DecimalFormat(userInfo.creditLimit) }} THB</p>
          </div>

        </div>

        <div v-if="$wait.is('loading-fetch-member')">
          <b-spinner
            small
            variant="primary"
          />
        </div>
      </div>

    </div>

    <!-- <b-navbar-nav class="nav align-items-center ml-auto"> -->
    <b-navbar-nav class="nav align-items-center">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!-- <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.username }} ({{ GetAgentGroupType(user) }})
            </p>
            <span class="user-status">{{ GetAgentGroupType(user) }}</span>
          </div> -->
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/profile.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{name: 'dashboard'}"
        >
          <feather-icon
            size="16"
            icon="HomeIcon"
            class="mr-50"
          />
          <span>Dashboard</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BIcon,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { DecimalFormat } from '@/utils/number'
import { mapGetters } from 'vuex'
import store from '@/store'
import axios from '@axios'
import { GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType } from '@/utils/agent'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BIcon,

    // Navbar Components
    DarkToggler,
    Locale,
    SearchBar,
    // NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    // userData: {
    //   type: Object,
    //   require: true,
    //   default: () => {},
    // },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      user: 'auth/user',
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    GetAgentGroupId,
    GetAgentGroupUsername,
    GetAgentGroupType,
    DecimalFormat,
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    async memberCreditReload() {
      this.$wait.start('loading-fetch-member')
      if (await store.dispatch('auth/getUserInfo')) {
        this.$wait.end('loading-fetch-member')
      }
    },
  },
}
</script>

<style scoped>
.user-status.credit {
  font-size: 16px!important;
}
.member-credit-reload {
  cursor: pointer;
  padding-right: 5px;
}
</style>
