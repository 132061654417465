export default [
  {
    header: 'Auto Partner',
  },
  {
    title: 'Auto partner',
    route: 'auto-partner-setting',
    icon: 'UserIcon',
    action: 'read',
    resource: 'AutoPartner',
  },
]
