export default [
  {
    header: 'Accounts Management',
  },
  {
    title: 'Accounts',
    route: 'accounts',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Accounts',
  },
  {
    title: 'Members',
    route: 'members',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Members',
  },
  {
    title: 'Sub Accounts',
    route: 'sub-accounts',
    icon: 'UserIcon',
    action: 'read',
    resource: 'SubAccounts',
  },
  {
    title: 'Member Clone Setting',
    route: 'member-clone-setting',
    icon: 'CopyIcon',
    action: 'read',
    resource: 'Members',
  },
]
